// Importar CSS
import '../style/style.css';
import '../style/aos.css';

// Importar dependências CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css/animate.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';

// Importar dependências JS
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'owl.carousel/dist/owl.carousel.min.js';
import 'vanilla-tilt/dist/vanilla-tilt.min.js';

// Importar scripts personalizados
import './owlsetting.js';
import './aos.js';
